<template>
  <div class="cloum" style="background: #fff">
    <div class="box2" style="padding: 30px 30px 14px">
      <div class="t" style="border: none">
        <span @mouseenter="clounhover('yj')" :class="culoumact == 'yj' ? 'active' : ''">一级建造师</span>
        <span @mouseenter="clounhover('ej')" :class="culoumact == 'ej' ? 'active' : ''">二级建造师</span>
        <span @mouseenter="clounhover('aq')" :class="culoumact == 'aq' ? 'active' : ''">中级注册安全工程师</span>
        <span @mouseenter="clounhover('jl')" :class="culoumact == 'jl' ? 'active' : ''">监理工程师</span>
        <span @mouseenter="clounhover('ys')" :class="culoumact == 'ys' ? 'active' : ''">执业药师</span>
      </div>
    </div>
    <div class="desc">
      <div class="bodybox">
        <div class="t" style="justify-content: space-between">
          <div class="l">
            <h2>{{ culoumdata.qh.tit }}</h2>
            <div class="tagp">
              <p v-for="item in culoumdata.qh.desc" :key="'qh' + item">
                {{ item }}
              </p>
            </div>
            <!-- <p>项目管理、工程经济、法规、建筑</p>
                <p>机电、市政公用、公路、水利水电</p>-->
          </div>
          <div class="r">
            <span @click="goshop(1)">查看课程列表</span>
            <span @click="show_type1">在线咨询</span>
          </div>
        </div>
        <div class="z">
          <div class="num">
            <div>
              {{ culoumdata.qh.jpkc.length }}<span>大</span>
              <p>精品课程</p>
            </div>
            <div>
              {{ culoumdata.qh.yzzl.length }}<span>份</span>
              <p>优质资料</p>
            </div>
            <div>
              {{ culoumdata.qh.zsfw.length }}<span>项</span>
              <p>服务赠送</p>
            </div>
          </div>
          <div class="tit">精品课程</div>
          <div class="kcbody" :style="
            culoumact == 'yj' || culoumact == 'ej'
              ? 'height:225px'
              : culoumact == 'aq'
              ? 'height:151px'
              : culoumact == 'jl'
              ? 'height:114px'
              : 'height:77px'
          ">
            <li :style="item.length > 8 ? 'width:100%' : ''" v-for="(item, i) in culoumdata.qh.jpkc" :key="'qh' + item">
              <span :style="`background:${color[i > 6 ? i % 6 : i]}`">
                <img src="../../assets/img/a798b7db18d6ecc6e4b1b8817b13f13.png" alt />
              </span>
              {{ item }}
            </li>
          </div>
          <div class="tit">优质资料</div>
          <div class="kcbody yzzl" :style="culoumact == 'ys' ? 'height:188px' : 'height:175px'">
            <li v-for="item in culoumdata.qh.yzzl" :key="'qh' + item">
              <img src="../../assets/img/5ed9de41bb61999e0d3bdce0c49d386.png" style="margin-right: 3px" alt />
              {{ item }}
            </li>
          </div>
          <div class="tit">赠送服务</div>
          <div class="kcbody zsfw" :style="culoumact == 'ys' ? 'height:151px' : 'height:188px'">
            <li v-for="item in culoumdata.qh.zsfw" :key="'qh' + item">
              <img src="../../assets/img/lansedui.png" alt />
              {{ item }}
            </li>
          </div>
          <div class="tit">课程备注</div>
          <div class="kcbz" :style="
            culoumact == 'ys'
              ? 'height:180px'
              : culoumact == 'yj' || culoumact == 'ej'
              ? 'height:298px'
              : 'height:316px'
          ">
            <li v-for="item in culoumdata.qh.kcbz" :key="'qh' + item.tit">
              <h2>
                <img src="../../assets/img/langou.png" alt />
                {{ item.tit }}
              </h2>
              <p v-for="a in item.desc" :key="'qh' + a">{{ a }}</p>
            </li>
          </div>
        </div>
        <div id="qhx" class="x">
          <div class="t">
            <span @mouseenter="qhprices(1)" :class="qhpricetype == 1 ? 'act' : ''">公共单科</span>
            <span @mouseenter="qhprices(2)" :class="qhpricetype == 2 ? 'act' : ''" v-if="culoumact != 'ys'">
              {{ culoumact == "jl" ? "案例分析" : "实务单科" }}
            </span>
            <span @mouseenter="qhprices(3)" :class="qhpricetype == 3 ? 'act' : ''">全科</span>
          </div>
          <div class="b">
            <div class="l">
              限时秒杀价：￥<span>{{ qhprice }}</span>
            </div>
            <div @click="goshop(1)" class="r">立即购买</div>
          </div>
        </div>
      </div>

      <div class="bodybox cx">
        <div class="t" style="justify-content: space-between">
          <div class="l">
            <h2>{{ culoumdata.cx.tit }}</h2>
            <div class="tagp">
              <p v-for="item in culoumdata.cx.desc" :key="'cx' + item">
                {{ item }}
              </p>
            </div>
            <!-- <p>项目管理、工程经济、法规、建筑</p>
                <p>机电、市政公用、公路、水利水电</p>-->
          </div>
          <div class="r">
            <span @click="goshop(2)">查看课程列表</span>
            <span @click="show_type1">在线咨询</span>
          </div>
        </div>
        <div class="z">
          <div class="num">
            <div>
              {{ culoumdata.cx.jpkc.length }}<span>大</span>
              <p>精品课程</p>
            </div>
            <div>
              {{ culoumdata.cx.yzzl.length }}<span>份</span>
              <p>优质资料</p>
            </div>
            <div>
              {{ culoumdata.cx.zsfw.length }}<span>项</span>
              <p>服务赠送</p>
            </div>
          </div>
          <div class="tit">精品课程</div>
          <div class="kcbody" :style="
            culoumact == 'yj' || culoumact == 'ej'
              ? 'height:225px'
              : culoumact == 'aq'
              ? 'height:151px'
              : culoumact == 'jl'
              ? 'height:114px'
              : 'height:77px'
          ">
            <li :style="item.length > 8 ? 'width:100%' : ''" v-for="(item, i) in culoumdata.cx.jpkc" :key="'cx' + item">
              <span :style="`background:${color[i > 6 ? i % 6 : i]}`">
                <img src="../../assets/img/a798b7db18d6ecc6e4b1b8817b13f13.png" alt />
              </span>
              {{ item }}
            </li>
          </div>
          <div class="tit">优质资料</div>
          <div class="kcbody yzzl" :style="culoumact == 'ys' ? 'height:188px' : 'height:175px'">
            <li v-for="item in culoumdata.cx.yzzl" :key="'cx' + item">
              <img src="../../assets/img/5ed9de41bb61999e0d3bdce0c49d386.png" alt style="margin-right: 3px" />
              {{ item }}
            </li>
          </div>
          <div class="tit">赠送服务</div>
          <div class="kcbody zsfw" :style="culoumact == 'ys' ? 'height:151px' : 'height:188px'">
            <li v-for="item in culoumdata.cx.zsfw" :key="'cx' + item">
              <img src="../../assets/img/huangdui.png" alt />
              {{ item }}
            </li>
          </div>
          <div class="tit">课程备注</div>
          <div class="kcbz" :style="
            culoumact == 'ys'
              ? 'height:180px'
              : culoumact == 'yj' || culoumact == 'ej'
              ? 'height:298px'
              : 'height:316px'
          ">
            <li v-for="item in culoumdata.cx.kcbz" :key="'cx' + item.tit">
              <h2>
                <img src="../../assets/img/huanggou.png" alt />
                {{ item.tit }}
              </h2>
              <p v-for="a in item.desc" :key="'qh' + a">{{ a }}</p>
            </li>
          </div>
        </div>
        <div id="bzx" class="x">
          <div class="t">
            <span @mouseenter="cxprices(1)" :class="cxpricetype == 1 ? 'act' : ''">
            {{ typeof culoumdata.cx.jgtitle !== 'undefined'? culoumdata.cx.jgtitle.gg : '公共单科' }}
            </span>
            <span @mouseenter="cxprices(2)" :class="cxpricetype == 2 ? 'act' : ''" v-if="culoumact != 'ys'">
              {{ culoumact == "jl" ? "案例分析" : (typeof culoumdata.cx.jgtitle !== 'undefined'? culoumdata.cx.jgtitle.sw : '实务单科') }}
            </span>
            <span @mouseenter="cxprices(3)" :class="cxpricetype == 3 ? 'act' : ''">
            {{ typeof culoumdata.cx.jgtitle !== 'undefined'? culoumdata.cx.jgtitle.qk : '全科' }}
            </span>
          </div>
          <div class="b">
            <div class="l">
              限时秒杀价：￥<span>{{ cxprice }}</span>
            </div>
            <div @click="goshop(2)" class="r">立即购买</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import culoum from "../../utils/cloum";
export default {
  name: "Homeculoum",
  props: {
    ids: "",
  },
  data() {
    return {
      qhprice: 0,
      qhpricetype: 1,
      cxprice: 0,
      cxpricetype: 1,
      color: ["#5559EE", "#DC3554", "#EDB742", "#DC3554", "#73BD4B", "#7AD5D4"],
      culoum: culoum,
      culoumact: "yj",
      culoumdata: culoum.yj,
    };
  },
  watch:{
    ids(val,oldval){
      
      // console.log(val)
    this.culoum.yj.qh.routerid.class_type_id = val.yijian[0];
    this.culoum.yj.cx.routerid.class_type_id = val.yijian[1];

    this.culoum.ej.qh.routerid.class_type_id = val.erjian[0];
    this.culoum.ej.cx.routerid.class_type_id = val.erjian[1];

    this.culoum.aq.qh.routerid.class_type_id = val.anquan[0];
    this.culoum.aq.cx.routerid.class_type_id = val.anquan[1];

    this.culoum.jl.qh.routerid.class_type_id = val.jianli[0];
    this.culoum.jl.cx.routerid.class_type_id = val.jianli[1];

    this.culoum.ys.qh.routerid.class_type_id = val.yaoshi[0];
    this.culoum.ys.cx.routerid.class_type_id = val.yaoshi[1];
    }
  },
  created() {
    
  },
  mounted() {
    //课程价格
    this.qhprices(1);
    this.cxprices(1);
  },
  methods: {
    goshop(e) {
      switch (e) {
        case 1:
          this.$router.push({
            path: `/shopdetail/${this.culoumdata.qh.routerid.course_category_id}/${this.culoumdata.qh.routerid.class_type_id}`,
          });
          break;
        case 2:
          this.$router.push({
            path: `/shopdetail/${this.culoumdata.cx.routerid.course_category_id}/${this.culoumdata.cx.routerid.class_type_id}`,
          });
          break;
      }
    },
    show_type1() {
      this.$emit("show_type1");
    },
    //课程价格切换
    qhprices(e) {
      this.qhpricetype = e;
      switch (e) {
        case 1:
          this.qhprice = this.culoumdata.qh.jg.gg;
          break;
        case 2:
          this.qhprice = this.culoumdata.qh.jg.sw;
          break;
        case 3:
          this.qhprice = this.culoumdata.qh.jg.qk;
          break;
      }
    },
    cxprices(e) {
      this.cxpricetype = e;
      switch (e) {
        case 1:
          this.cxprice = this.culoumdata.cx.jg.gg;
          this.cxpricetitle = typeof this.culoumdata.cx.jgtitle !== 'undefined' ? this.culoumdata.cx.jgtitle.gg : '公共单科';
          break;
        case 2:
          this.cxprice = this.culoumdata.cx.jg.sw;
          this.cxpricetitle = typeof this.culoumdata.cx.jgtitle !== 'undefined' ? this.culoumdata.cx.jgtitle.sw : '实务单科';
          break;
        case 3:
          this.cxprice = this.culoumdata.cx.jg.qk;
          this.cxpricetitle = typeof this.culoumdata.cx.jgtitle !== 'undefined' ? this.culoumdata.cx.jgtitle.qk : '全科';
          break;
      }
    },
    //课程切换
    clounhover(e) {
      this.culoumact = e;
      switch (e) {
        case "yj":
          this.culoumdata = this.culoum.yj;
          break;
        case "ej":
          this.culoumdata = this.culoum.ej;
          break;
        case "aq":
          this.culoumdata = this.culoum.aq;
          break;
        case "jl":
          this.culoumdata = this.culoum.jl;
          break;
        case "ys":
          this.culoumdata = this.culoum.ys;
          break;
      }
      this.qhprices(1);
      this.cxprices(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.box2 {
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;

  .t {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    border-bottom: 2px solid #f7f9fc;

    .active {
      color: #3e7eff;
      position: relative;
    }

    .active::after {
      content: "";
      display: block;
      width: 26px;
      height: 3px;
      background: #3e7eff;
      position: absolute;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
    }

    span {
      margin: 0 70px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4d5063;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.cloum {
  .desc {
    display: flex;
    padding: 20px;

    .cx .z .kcbz li h2 {
      color: #dd8003 !important;
    }

    .cx .z .tit {
      background: #faf4ec !important;
      color: #5d3914 !important;
    }

    .cx .t .r span:first-child {
      color: #4c2c0a !important;
    }

    .cx .t .l {

      p,
      h2 {
        color: #4c2c0a !important;
      }
    }

    .cx {
      background: linear-gradient(90deg, #faebd3 0%, #d8b77a 99%) !important;
    }

    .bodybox {
      line-height: 1;
      width: 50%;
      position: relative;
      box-sizing: border-box;
      padding: 30px 20px 210px;
      border-radius: 20px;
      background: linear-gradient(90deg, #0070e9 0%, #55a5fd 99%);

      .x {
        background: #ffffff;
        border-radius: 20px;
        position: absolute;
        width: 640px;
        bottom: 0;
        z-index: 110;
        padding: 30px;
        // box-shadow: 0 0 10px #eee;
        box-sizing: border-box;

        .t {
          display: flex;
          align-items: center;

          // justify-content: space-between;
          span {
            width: 186px;
            margin-right: 10px;
            box-sizing: border-box;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #f4f4f4;
            border-radius: 6px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }

          .act {
            box-sizing: border-box;
            background: rgba(219, 40, 21, 0.05);
            color: #ff2e17;
            border: 1px solid #ff2e17;
            position: relative;
          }
        }

        .b {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .l {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ff2e17;
            line-height: 30px;

            span {
              font-size: 34px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ff2e17;
              line-height: 30px;
            }
          }

          .r {
            cursor: pointer;
            text-align: center;
            width: 340px;
            height: 50px;
            background: linear-gradient(90deg, #ff6754 0%, #ff2e17 100%);
            border-radius: 6px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 50px;
          }
        }
      }

      .t {
        display: flex;

        // justify-content: space-between;
        // align-items: center;
        .l {
          h2 {
            font-size: 32px;
            font-family: FZZhengHeiS-DB-GB;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 20px;
          }

          .tagp {
            display: flex;
            width: 420px;
            flex-wrap: wrap;
          }

          p {
            // width: 270px;
            line-height: 26px;
            font-size: 18px;
            font-family: FZZhengHeiS-M-GB;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .r {
          display: flex;
          flex-direction: column;

          span {
            cursor: pointer;
            width: 180px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            border-radius: 23px;
            font-size: 20px;
            font-family: FZZhengHeiS-M-GB;
            font-weight: 400;
            color: #0070e9;
            background: #ffffff;
          }
        }

        .r span:last-child {
          background: none;
          box-sizing: border-box;
          color: #fff;
          margin-top: 12px;
          border: 2px solid #ffffff;
        }
      }

      .z {
        margin: 35px 0;
        padding: 30px;
        background: #fff;
        border-radius: 20px;

        .num {
          padding: 17px 37px 25px;
          display: flex;
          justify-content: space-between;

          div {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 600;
            text-align: center;
            color: #2f2f2f;

            span {
              font-size: 24px;
            }
          }

          p {
            font-size: 20px;
            margin-top: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }

        .tit {
          margin-top: 20px;
          padding: 20px 24px;
          border-radius: 10px 10px 0px 0px;
          background: #edf5fd;
          font-size: 22px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #2d466d;
        }

        .kcbody {
          box-sizing: border-box;
          // min-height: 225px;
          padding: 30px 0px 10px 24px;
          display: flex;
          flex-wrap: wrap;
          background: #fff;
          border-radius: 0px 0px 10px 10px;
          box-shadow: 0 5px 10px 0px #eee;
          align-content: flex-start;

          li {
            width: 33.3%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            margin-bottom: 19px;
            color: #3c3d45;
            background: #ffffff;
            border-radius: 0px 0px 10px 10px;

            span {
              width: 16px;
              height: 16px;
              background: #5559ee;
              border-radius: 4px;
              text-align: center;
              margin-right: 10px;
              line-height: 14px;

              img {
                width: 8px;
                height: 10px;
              }
            }
          }
        }

        .yzzl {

          // min-height: 188px;
          li {
            width: 50%;

            img {
              width: 10px;
              height: 10px;
            }
          }
        }

        .zsfw {

          // min-height: 188px;
          li {
            img {
              margin-right: 5px;
            }
          }
        }

        .kcbz {
          box-sizing: border-box;
          // min-height: 316px;
          padding: 30px 24px 10px;
          background: #fff;
          border-radius: 0px 0px 10px 10px;
          box-shadow: 0 0 10px #eee;

          li {
            margin-bottom: 20px;

            h2 {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #456ff4;

              img {
                width: 10px;
                height: 10px;
                margin-right: 4px;
              }
            }

            p {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}
</style>