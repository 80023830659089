<template>
  <div style="background: #f6f9fc" id="totop">
    <div v-if="index_v1.top_pic_list != ''" class="hotbanner" style="position: relative">
      <img src="../../assets/img/close@2x.png" alt class="hotclose" @click="index_v1.top_pic_list = ''" style="
          top: 5px;
          right: 5px;
          width: 40px !important;
          height: 40px;
          cursor: pointer;
        " />
      <a style="display: flex" :href="index_v1.top_pic_list[0].url">
        <img :src="index_v1.top_pic_list[0].image" alt style="width: 100%; max-height: 100px" />
      </a>
    </div>
    <Tabs></Tabs>
    <!-- 版心 -->
    <!-- banner 轮播 -->
    <el-carousel @change="intervalChange" :interval="interval" arrow="always" style="cursor: pointer">
      <img :src="index_v1.pic_list[0].image" alt style="width: 100%; opacity: 0; min-width: 1400px" />
      <el-carousel-item v-for="(item, index) in index_v1.pic_list" :key="index">
        <el-image :src="item.image" @click.native="openLink(item)" fit="cover"></el-image>
        <!-- <img class="banner" :src="item.image" @click.native="openLink(item)" alt="立优教育" /> -->
      </el-carousel-item>
    </el-carousel>
    <!-- 通知 -->
    <div class="notice_box" v-show="index_v1.inform_list != ''">
      <div style="
          width: 1400px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        ">
        <div class="one">
          <img src="../../assets/img/tongzhi.png" alt="立优教育" />
          <p>通知:</p>
        </div>

        <div class="notice" @mouseover="stopScroll" @mouseout="startScroll">
          <div class="contlist" ref="contlist">
            <div ref="notic" style="padding-left: 20px; width: auto">
              <span v-for="item in index_v1.inform_list" :key="item.id">{{
              item.content
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="content">
      <!-- 考试速递 -->
      <div class="box1">
        <div class="l">
          <h2>
            考试
            <span style="color: #3e7eff">速递</span>
          </h2>
          <div class="b">
            <div class="bl">
              <h1 style="display: flex">
                <img src="https://res-qd.liyouedu.cn/resources/website/img/guanwang_sudi_34_2022042001.jpg"
                  alt="立优教育" />
              </h1>
              <img :src="index_v1.exam_next_list[0].image" @click="openLink(index_v1.exam_next_list[0])" alt="立优教育" />
            </div>
            <div class="br isred">
              <ul>
                <li @click="goNewsInfo(item)" :key="item.id" v-for="(item, index) in index_v1.top_article_list">
                  <span>{{ item.title }}</span>
                  <img v-show="index == 0 || index == 4" src="../../assets/img/dafe5c7d4164d606af15faa8aac73ad.png"
                    alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="r">
          <LoginCard />
        </div>
      </div>
      <!-- 标题 -->
      <div class="title_box">
        <div>Architecture</div>
        <div>建筑类</div>
      </div>
      <div class="box2">
        <div class="t">
          <span @mouseenter="navhover(1)" :class="navhovertype === 1 ? 'active' : ''">一级建造师</span>
          <span @mouseenter="navhover(2)" :class="navhovertype === 2 ? 'active' : ''">二级建造师</span>
          <span @mouseenter="navhover(3)" :class="navhovertype === 3 ? 'active' : ''">中级注册安全工程师</span>
          <span @mouseenter="navhover(4)" :class="navhovertype === 4 ? 'active' : ''">监理工程师</span>
        </div>
        <div class="b">
          <div class="bl">
            <div style="display: flex">
              <div class="blt" style="position: relative">
                <Videos style="position: absolute" @videoover="videoover1show = false" v-if="culoumvideoshow"
                  :vid="culoumvideo.polyv_vid" :url="culoumvideo.video_url" />
                <div v-show="videoover1show" class="imgmask" @click="culoumvideoshow = true">
                  <div class="mask">
                    <div class="loadingimg">
                      <img v-show="!culoumvideoshow" src="../../assets/img/bofanghome.png" alt="" />
                      <i v-show="culoumvideoshow" style="color: #fff; font-size: 50px" class="el-icon-loading"></i>
                    </div>
                    <p>
                      <span class="text1">
                        {{ culoumvideo.course_name }}
                      </span>
                    </p>
                  </div>
                  <img :src="culoumvideo.course_cover" alt="" />
                </div>
              </div>
              <div class="blb" style="flex-direction: column; margin: 0 14px">
                <div v-for="item in jzdata.course_list" :key="'jz' + item.id" @click="jzvideohover(item)"
                  :class="item.id == culoumvideo.id ? 'img activee' : 'img'" style="width: 218px; height: 120px">
                  <div class="mask">
                    <p>
                      <span class="text1">
                        {{ item.course_name }}
                      </span>
                    </p>
                  </div>
                  <img :src="item.course_cover" alt />
                </div>
                <!-- <img
                  class="morepos"
                  @click="$router.push('/audition/3')"
                  src="../../assets/img/e627b426230eee61cdefb22d8a6a863.png"
                  alt=""
                /> -->
                <!-- <img
                  style="cursor: pointer"
                  @click="$router.push('/audition/3')"
                  src="../../assets/img/030f41ccc82580d3e2c8eb692c14b7f.png"
                  alt=""
                /> -->
              </div>
            </div>

            <div class="brb" style="margin-top: 3px; padding-right: 18px">
              <div class="brbl">
                <a v-for="item in jzdata.jump_list" :key="item.title" @click="jump_list(item)" target="_blank">
                  <span style="margin-top: 21px">
                    {{ item.title }}
                    <img :src="item.icon" alt />
                  </span>
                </a>
              </div>
              <div class="brbr" style="
                  margin-top: 21px;
                  width: 380px;
                  border-radius: 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                ">
                <img style="width: 138px" @click="opennews(1)" src="../../assets/img/zixun1@2x.png" alt="建造师" />
                <img style="width: 218px" @click="toaudition(1)" src="../../assets/img/jzzx123.png" alt="建造师" />
              </div>
            </div>
          </div>
          <div class="br">
            <div class="brt">
              <ul>
                <li @click="goNewsInfo(item)" v-for="item in jzdata.article" :key="'jzac' + item.id">
                  <span>{{ item.title }}</span>
                  <!-- <span>{{ getLocalTime(item.createtime).split(" ")[0] }}</span> -->
                </li>
                <!-- <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="title_box">
        <div>Architecture</div>
        <div>医学类</div>
      </div>
      <div class="box2">
        <div class="t">
          <span class="active">执业药师</span>
        </div>
        <div class="yb b">
          <div class="br">
            <div class="brb">
              <div class="brbl ysspan">
                <a v-for="item in index_v1.yaoshi_list.jump_list" :key="item.title" herf="javascript;"
                  @click="jump_list(item)" target="_blank">
                  <span>
                    {{ item.title }}
                    <img :src="item.icon" alt />
                  </span>
                </a>
              </div>
            </div>
            <div class="brt">
              <ul style="margin-top: 12px">
                <li @click="goNewsInfo(item)" v-for="item in index_v1.yaoshi_list.article" :key="'ys' + item.id">
                  <span>{{ item.title }}</span>
                  <!-- <span>{{ getLocalTime(item.createtime).split(" ")[0] }}</span> -->
                </li>
                <!-- <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>
                <li>
                  <span>海南人社厅：2021年一建考试成绩预计在一周内进行公布</span>
                  <span>2021-2-16</span>
                </li>-->
              </ul>
              <div style="
                  display: flex;
                  justify-content: space-between;
                  cursor: pointer;
                ">
                <img @click="opennews(2)" src="../../assets/img/zixun2@2x.png" alt="执业药师" style="width: 146px" />
                <img src="../../assets/img/400@2x.png" alt="执业药师" style="width: 278px" />
              </div>
            </div>
          </div>
          <div class="bl">
            <div class="blt" style="position: relative">
              <Videos @videoover="videoovershow = false" v-if="culoumvideoyshow" :vid="culoumvideoy.polyv_vid"
                :url="culoumvideoy.video_url" id="ysplayer" />
              <div v-show="videoovershow" class="imgmask" @click="culoumvideoyshow = true">
                <div class="mask">
                  <div class="loadingimg">
                    <img v-show="!culoumvideoyshow" src="../../assets/img/bofanghome.png" alt="" />
                    <i v-show="culoumvideoyshow" style="color: #fff; font-size: 50px" class="el-icon-loading"></i>
                  </div>
                  <p>
                    <span class="text1">
                      {{ culoumvideoy.course_name }}
                    </span>
                  </p>
                </div>
                <img :src="culoumvideoy.course_cover" alt="" />
              </div>
            </div>
            <div class="blb">
              <div v-for="item in index_v1.yaoshi_list.course_list" :key="'yskc' + item.id" herf="javascript;"
                @click="ysvideohover(item)" :class="culoumvideoy.id == item.id ? 'img active' : 'img'">
                <div class="mask">
                  <p>
                    <span class="text1">
                      {{ item.course_name }}
                    </span>
                  </p>
                </div>
                <img :src="item.course_cover" alt />
              </div>
              <!-- <img
                class="morepos"
                @click="$router.push('/audition/3')"
                src="../../assets/img/e627b426230eee61cdefb22d8a6a863.png"
                alt=""
              /> -->
              <img @click="toaudition(2)" style="cursor: pointer; height: 120px; margin-left: 6px"
                src="../../assets/img/5c2716547e5d10d637b08f74bf8a21f.png" alt="" />
              <!-- <div class="img">
                <img src alt />
              </div>
              <div class="img">
                <img src alt />
              </div>-->
            </div>
          </div>
        </div>
      </div>

      <!-- 标题 -->
      <div class="title_box">
        <div>Architecture</div>
        <div>课程推荐</div>
      </div>
      <Culoum @show_type1="show_type1" :ids="index_v1.goods_ids" />

      <!-- 标题 -->
      <div class="title_box">
        <div>Architecture</div>
        <div>名师直播</div>
        <div class="more" @click="$router.push({ path: '/livelist/11' })">
          更多直播
          <img src="../../assets/img/lijibofang.png" alt="" />
        </div>
      </div>
      <div class="live">
        <Homelive :live="index_v1.live_data.list" />
        <!-- <div @click="_live_detail(liveleft.live_id)" class="l">
          <div class="lt">
            <img src="../../assets/img/shang.png" alt="" />
          </div>
          <div class="lb">
            <div class="lbtit text1">
              {{ liveleft.live_title }}
            </div>
            <div class="lbbot">
              <div class="botl">
                <img :src="liveleft.teacher_image" alt="" />
              </div>
              <div class="botr">
                <div class="botrt">
                  {{ liveleft.teacher_name }}
                </div>
                <div class="botrb">
                  {{ liveleft.cate_name }}
                </div>
              </div>
            </div>
            <div class="lbbuton">
              <div class="butl">
                直播时间：{{ Number(liveleft.start_time.split("-")[1]) }}月{{
                  Number(liveleft.start_time.split("-")[2])
                }}日<span
                  >丨{{
                    liveleft.dispark_time.split(" ")[1].split(":")[0] +
                    ":" +
                    liveleft.dispark_time.split(" ")[1].split(":")[1]
                  }}-{{ liveleft.finish_time.split(" ")[1] }}</span
                >
              </div>
              <img
                v-show="liveleft.n == 1"
                src="../../assets/img/zhibo.png"
                alt=""
              />
              <img
                v-show="liveleft.n == 2"
                src="../../assets/img/kaishi.png"
                alt=""
              />
              <img
                v-show="liveleft.n == 3"
                src="../../assets/img/jieshu (1).png"
                alt=""
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="r">
          <ul>
            <li
              v-for="(item, index) in index_v1.live_data.list"
              :key="'live333' + index"
            >
              <div class="t">
                <div class="img">
                  <img
                    v-if="item.n == 1"
                    src="../../assets/img/30dd96515676398aa5358359149a4e1.png"
                    alt
                  />
                  <img
                    v-else
                    src="../../assets/img/1ac475824e21818bbeff6039db9acf8.png"
                    alt
                  />
                </div>
                {{ item.start_date }}
                <span>{{ item.dispark_time.split(" ")[1] }}</span>
              </div>
              <div
                @mouseenter="livehover(item)"
                @click="_live_detail(item.live_id)"
                :class="item.n == 3 ? 'b jsj' : 'b'"
              >
                {{ item.live_title }}
                <span class="but zbz" v-if="item.n == 1">
                  <img src="../../assets/img/zhibozhong.png" alt />
                  进入直播
                </span>
                <span class="but jjks" v-if="item.n == 2">
                  <img src="../../assets/img/jijiang.png" alt />
                  即将开始
                </span>
                <span class="but js" v-if="item.n == 3">
                  <img src="../../assets/img/jieshu.png" alt />
                  已结束
                </span>
              </div>
            </li>
          </ul>
        </div> -->
      </div>

      <!-- 标题 -->
      <div class="title_box">
        <div>Architecture</div>
        <div>网校名师</div>
      </div>
      <Teacher />

      <!-- 标题 -->
      <div class="title_box">
        <div>Architecture</div>
        <div>我们的优势</div>
      </div>
      <About />

      <!-- 版心结尾 -->
    </div>
    <!-- 左右吸附 -->
    <Fixedl id="fixedl" />
    <Fixedr id="fixedr" :seat_jianzhu="index_v1.seat_jianzhu" :seat_yaoshi="index_v1.seat_yaoshi" />

    <!-- 底部体验课 -->
    <div class="experience">
      <!-- <div class="cut_bj_zuo" style="width: 100%">
        <img src="../../assets/img/beijing_zuo.png" alt />
      </div> -->
      <div class="experience_btn">
        <img src="../../assets/img/home_bg.png" style="height: 90px; margin-right: 50px" alt="" />
        <div class="btn_left">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item>
              <el-select v-model="formInline.subject_id" placeholder="请选择专业">
                <el-option v-for="item in subjectList" :key="item.subject_id" :label="item.name"
                  :value="item.subject_id"></el-option>
                <!-- <el-option label="区域二" value="beijing"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input maxlength="11" v-model="formInline.mobile" placeholder="请输入手机号" style="margin: 0 10px">
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="at_once" type="primary" @click="onSubmit">
                <span style="font-size: 22px; color: #ffc225">0元</span>体验
              </div>
            </el-form-item>
          </el-form>
          <!-- <el-input v-model="form.mobile" placeholder="请输入内容"></el-input>
          <button class="submit" type="submit">立即体验</button>-->
        </div>
        <img class="btn_right" src="../../assets/img/guanbi.png" alt="立优教育" @click="hiddenClock" />
      </div>

      <!-- </div> -->
      <!-- <div class="cut_bj_you" style="width: 100%">
        <img src="../../assets/img/beijing_you.png" alt />
      </div> -->

      <!-- <img
        class="cut_bj cut_bj_you"
        src="../../assets/img/beijing_you.png"
        alt="立优教育"
      />-->
    </div>
    <div class="show_btn show_free_btn" style="z-index: 9" @click="showClock">
      免费领取
    </div>
    <div v-show="hotshow" class="hotmask" @click="hotshow = false"></div>
    <div v-show="hotshow" class="hotimg">
      <img :src="hotimg.image" alt @click="hoturl(hotimg.url)" />
      <img src="../../assets/img/close@2x.png" alt class="hotclose" @click="hotshow = false" />
    </div>

    <!-- 左右吸附 -->

    <div v-show="hotshow" class="hotmask" @click="hotshow = false"></div>
    <div v-show="hotshow" class="hotimg">
      <img :src="hotimg.image" alt @click="hoturl(hotimg.url)" />
      <img src="../../assets/img/close@2x.png" alt class="hotclose" @click="hotshow = false" />
    </div>
    <el-dialog class="bmdialog" title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <div class="detail">
        <p>
          {{
          show_type != 2 ? "" : "暂未开通直播权限,"
          }}请留下您的报考科目和联系方式
        </p>
        <p v-show="show_type != 3">班主任老师将尽快为您开通～</p>
      </div>
      <el-form ref="form" :model="bmform" label-width="100px">
        <el-form-item label="报考专业：">
          <el-select v-model="bmform.region" placeholder="请选择专业">
            <el-option v-for="item in subjectList" :key="item.subject_id" :label="item.name" :value="item.subject_id">
            </el-option>
            <!-- <el-option label="区域二" value="beijing"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input maxlength="11" v-model="bmform.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bmsubmit">确 定</el-button>
      </span>
    </el-dialog>

    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
import Videos from "../../components/videos";
import Homelive from "../../components/homelive";
import Fixedl from "../../components/fixedLeft";
import Fixedr from "../../components/fixedright";
import LoginCard from "../../components/logincard";
import About from "../../components/homeabout";
import Teacher from "../../components/hometeacher";
import Culoum from "../../components/homeculoum";

export default {
  metaInfo: {
    title: "首页",
    meta: [
      {
        name: "keywords",
        content: "立优教育",
      },
      {
        name: "description",
        content: "立优教育",
      },
    ],
  },
  components: {
    Videos,
    LoginCard,
    Fixedl,
    Fixedr,
    About,
    Culoum,
    Homelive,
    Teacher,
  },
  data() {
    return {
      show_type: 1,
      videoover1show: true,
      videoovershow: true,
      culoumvideoshow: false,
      culoumvideo: {
        video_url: "", // 视频地址
        polyv_vid: "",
        id: "",
      },
      culoumvideoyshow: false,
      culoumvideoy: {
        video_url: "", // 视频地址
        polyv_vid: "",
        id: "",
      },
      jzdata: {},
      hotbannerSrc: "",
      interval: 10000,
      audition: [],
      auditionis: "",
      auditionloading: false,
      culoumloading: false,
      hotimg: "",
      hotshow: false,
      show_type: 2,
      dialogVisible: false,
      bmform: {
        mobile: "",
        region: "",
      },

      scrollW: 0, //记录滚动到哪了
      notice_list: "", //系统公告
      choose_btn: 1135, //课程推荐按钮选中状态
      btnStatus: false,
      subjectList: [], // 科目列表
      formInline: {
        mobile: "", // 手机号
        subject_id: "", // 科目id
      },
      loading: true, //加载状态
      isshow: true,

      //新版首页
      navhovertype: 1,
      liveleft: {
        start_date: "",
        start_time: "",
        dispark_time: "",
      },

      index_v1: {
        seat_yaoshi: [
          {
            image: "",
          },
        ],
        seat_jianzhu: [
          {
            image: "",
          },
        ],
        pic_list: [
          {
            id: 16,
            image:
              "",
            image2: "",
            url: "",
            content: "",
            stand_second: 0,
            position: 9,
            jump_mode: "1",
          },
        ],
        inform_list: [],
        top_pic_list: [],
        activity_pic_list: [],
        exam_prev_list: [
          {
            image: "",
          },
        ],
        exam_next_list: [
          {
            image: "",
          },
        ],
        top_article_list: [
          {
            id: 114,
            title: "",
          },
        ],
        yijian_list: {
          course_list: [
            {
              id: 1531,
              course_name:
                "",
              video_url: "",
              polyv_vid: "",
              two_coursecate_id: 1135,
            },
          ],
          article: [
            {
              id: 107,
              title: "",
              course_category_id: 1135,
            },
          ],
        },
        erjian_list: {
          course_list: [
            {
              id: 1499,
              course_name:
                "",
              video_url: "",
              polyv_vid: "",
              two_coursecate_id: 1237,
            },
          ],
          article: [
            {
              id: 110,
              title: "",
              course_category_id: 1145,
            },
          ],
        },
        anquan_list: {
          course_list: [
            {
              id: 1551,
              course_name:
                "",
              video_url: "",
              polyv_vid: "",
              two_coursecate_id: 1238,
            },
          ],
          article: [
            {
              id: 108,
              title: "",
              course_category_id: 1154,
            },
          ],
          article: [
            {
              id: 114,
              title: "",
              course_category_id: 1291,
            },
          ],
        },
        yaoshi_list: {
          course_list: [
            {
              id: 1269,
              course_name: "",
              video_url:
                "",
              polyv_vid: "",
              two_coursecate_id: 1239,
            },
          ],
          article: [
            {
              id: 116,
              title: "",
              course_category_id: 1165,
            },
          ],
        },
        live_data: {
          list: [
            {
              id: 7,
              live_id: 10,
              live_field_id: 10,
              dispark_time: "",
              start_time: 1643090685,
              start_date: "",
              teacher_name: "",
              teacher_image:
                "",
              live_title: "",
              live_images:
                "",
              cate_name: "",
              order_time: 4475400,
              n: 3,
            },
          ],
        },
        faculty_list: [
          {
            id: 27,
            name: "",
            image:
              "",
            brief_desc: "",
            detail_desc:
              "",
            tag_id: "4",
            m_image:
              "",
          },
        ],
        seat_yaoshi: [
          {
            image: "",
          },
        ],
        seat_jianzhu: [
          {
            image: "",
          },
        ],
      },
    };
  },
  mounted() {
    api.index_v1().then((res) => {
      // console.log(res);
      if (res.data.data.activity_pic_list[0]) {
        this.hotshow = true;
        this.hotimg = res.data.data.activity_pic_list[0];
      }
      let a = [];
      res.data.data.live_data.list.forEach((e) => {
        e.live.forEach((c) => {
          c.start_time = this.getLocalTime(c.start_time);
          c.start_time = c.start_time.split(" ")[0];
          c.finish_time = this.getLocalTime(c.finish_time);
          a.push(c);
        });
      });
      this.liveleft = a[0];
      res.data.data.live_data.list = a;
      res.data.data.yijian_list.article =
        res.data.data.yijian_list.article.splice(0, 13);
      res.data.data.yijian_list.course_list =
        res.data.data.yijian_list.course_list.splice(0, 3);
      this.culoumvideo = res.data.data.yijian_list.course_list[0];

      res.data.data.yaoshi_list.article =
        res.data.data.yaoshi_list.article.splice(0, 9);
      res.data.data.yaoshi_list.course_list =
        res.data.data.yaoshi_list.course_list.splice(0, 3);
      this.culoumvideoy = res.data.data.yaoshi_list.course_list[0];

      this.jzdata = res.data.data.yijian_list;
      this.index_v1 = res.data.data;
      this.index_v1.seat_jianzhu = res.data.data.seat_jianzhu?.[0]?.image;
      this.index_v1.seat_yaoshi = res.data.data.seat_yaoshi?.[0]?.image;
      this.interval = res.data.data.pic_list[0].stand_second
        ? Number(res.data.data.pic_list[0].stand_second) * 1000
        : 5000;
      // console.log(this.index_v1.inform_list);
      if (this.index_v1.inform_list.length > 0) {
        setTimeout(() => {
          this.scroll();
        }, 1500);
      }
    });
    //实例挂载完毕前

    this.finedscroll();
  },
  methods: {
    //跳转试听课
    toaudition(e) {
      if (e == 1) {
        switch (Number(this.navhovertype)) {
          case 1:
            this.$router.push({ path: "/audition/3", query: { type: 1 } }); //一建
            break;
          case 2:
            this.$router.push({ path: "/audition/3", query: { type: 2 } }); //二建
            break;
          case 3:
            this.$router.push({ path: "/audition/3", query: { type: 3 } }); //安全
            break;
          case 4:
            this.$router.push({ path: "/audition/3", query: { type: 5 } }); //监理
            break;
        }
      } else {
        this.$router.push({ path: "/audition/3", query: { type: 4 } });//药师
      }
    },
    //跳转资讯
    opennews(e) {
      if (e == 1) {
        switch (Number(this.navhovertype)) {
          case 1:
            this.$router.push({ path: "/information/5", query: { type: 1 } }); //一建
            break;
          case 2:
            this.$router.push({ path: "/information/5", query: { type: 2 } }); //二建
            break;
          case 3:
            this.$router.push({ path: "/information/5", query: { type: 3 } }); //安全
            break;
          case 4:
            this.$router.push({ path: "/information/5", query: { type: 5 } }); //监理
            break;
        }
      } else {
        this.$router.push({ path: "/information/5", query: { type: 4 } });//药师
      }
    },
    jump_list(e) {
      if (e.jump_url == "") {
        this.show_type = 3;
        this.dialogVisible = true;
        // this.getSubject();
      } else {
        window.open(e.jump_url);
      }
    },
    // 科目列表
    getSubject() {
      api.subject().then((res) => {
        this.subjectList = res.data.data;
      });
    },
    // 去资讯详情页
    // 去资讯详情页
    goNewsInfo(e) {
      // console.log(e);
      if (e.metatype == 2) {
        window.open(e.content_url);
      } else {
        this.$router.push({
          name: "Info",
          params: { is_pageid: 5, info_id: e.id },
        });
      }
    },
    // goNewsInfo(id) {
    //   this.$router.push({
    //     name: "Info",
    //     params: { is_pageid: 5, info_id: id },
    //   });
    // },
    //建筑药师 6张小图片点击
    img6click() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$router.push({ path: "/personal/8", query: { type: 3 } });
      } else {
        let routeData = this.$router.resolve({ path: "/login" });
        window.open(routeData.href, "_blank");
      }
    },
    //建筑药师 试听课切换
    jzvideohover(e) {
      this.culoumvideo = e;
      this.videoover1show = true;
      this.culoumvideoshow = false;
    },
    ysvideohover(e) {
      this.culoumvideoy = e;
      this.videoovershow = true;
      this.culoumvideoyshow = false;
    },

    //直播切换
    livehover(e) {
      this.liveleft = e;
    },
    navhover(e) {
      if (e == this.navhovertype) {
        return;
      } else {
        this.navhovertype = e;
        // this.culoumvideoshow = false;
        switch (e) {
          case 1:
            this.jzdata = this.index_v1.yijian_list;
            break;
          case 2:
            this.jzdata = this.index_v1.erjian_list;
            break;
          case 3:
            this.jzdata = this.index_v1.anquan_list;
            break;
          case 4:
            this.jzdata = this.index_v1.jianli_list;
            break;
        }
        let a = this.jzdata.article;
        a = a.splice(0, 13);
        let b = this.jzdata.course_list;
        b = b.splice(0, 3);
        this.jzdata.article = a;
        this.jzdata.course_list = b;
        if (!this.culoumvideoshow) {
          this.culoumvideo = b[0];
        }
      }
    },
    //时间戳转时间
    getLocalTime(str) {
      var n = parseInt(str) * 1000;
      var D = new Date(n);
      var year = D.getFullYear(); //四位数年份

      var month = D.getMonth() + 1; //月份(0-11),0为一月份
      month = month < 10 ? "0" + month : month;

      var day = D.getDate(); //月的某一天(1-31)
      day = day < 10 ? "0" + day : day;

      var hours = D.getHours(); //小时(0-23)
      hours = hours < 10 ? "0" + hours : hours;

      var minutes = D.getMinutes(); //分钟(0-59)
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // var seconds = D.getSeconds();//秒(0-59)
      // seconds = seconds<10?('0'+seconds):seconds;
      // var week = D.getDay();//周几(0-6),0为周日
      // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

      var now_time =
        year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      return now_time;
    },

    //直播详情
    _live_detail(val) {
      let data = {
        live_id: val,
      };
      api.live_detail(data).then((res) => {
        //  console.log(res)
        if (res.data.code == 1) {
          //  console.log(res.data.data.url)
          window.open(res.data.data.url);
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        } else if (parseInt(res.data.code) == 0) {
          if (res.data.data.error_code == 4) {
            this.dialogVisible = true;
            this.show_type = 2;
            // this.getSubject();
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    show_type1() {
      this.show_type = 1;
      this.dialogVisible = true;
      // this.getSubject();
    },
    // banner 有链接跳转对应地址
    openLink(e) {
      // let token = localStorage.getItem('token')
      if (e.url != "") {
        if (e.jump_mode == 2) {
          window.open(e.url);
        } else {
          window.location.href = e.url;
        }
      }
    },

    finedscroll() {
      // let distance =document.documentElement.scrollTop||document.body.scrollTop; //变量distance就是滚动条滚动时，到顶部的距离
      window.onscroll = function () {
        let fixedr = document.getElementById("fixedr");
        let fixedl = document.getElementById("fixedl");
        let bodybox = document.querySelector(".bodybox");
        let bodyboxh = bodybox.offsetHeight;
        let bodyboxth = bodybox.offsetTop;
        let viewheight = document.body.clientHeight;
        // console.log(viewheight)
        let qhx = document.getElementById("qhx");
        let bzx = document.getElementById("bzx");
        let toTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (toTop == 0) {
          qhx.style.position = "absolute";
          bzx.style.position = "absolute";
          qhx.style.bottom = "30px";
          bzx.style.bottom = "30px";
        }
        // console.log(toTop);
        if (toTop > bodyboxth) {
          if (toTop - bodyboxth < bodyboxh - 220) {
            qhx.style.position = toTop - bodyboxth < 300 ? "absolute" : "fixed";
            bzx.style.position = toTop - bodyboxth < 300 ? "absolute" : "fixed";
            qhx.style.bottom = "0px";
            bzx.style.bottom = "0px";
            // qhx.style.top = `${toTop - bodyboxth < 330 ? 330:toTop - bodyboxth }px`;
            // bzx.style.top = `${toTop - bodyboxth < 330 ? 330:toTop - bodyboxth }px`;
          } else {
            qhx.style.position = "absolute";
            bzx.style.position = "absolute";
            qhx.style.bottom = "30px";
            bzx.style.bottom = "30px";
            // qhx.style.top = `${bodyboxh - 220 }px`;
            // bzx.style.top = `${bodyboxh - 220 }px`;
          }
        }

        if (toTop < 690) {
          if (690 - toTop > 230) {
            fixedr.style.top = `${690 - toTop}px`;
            fixedl.style.top = `${690 - toTop}px`;
          } else {
            fixedr.style.top = `230px`;
            fixedl.style.top = `230px`;
          }
        }
      };
    },

    //鼠标悬停，停止滚动
    stopScroll() {
      if (
        this.index_v1.inform_list.length == 1 &&
        this.index_v1.inform_list[0].content.length > 10
      ) {
        var target = this.$refs.contlist;
        clearInterval(this.scrollTime);
      }
    },

    //鼠标移开 ，接着滚动
    startScroll() {
      if (
        this.index_v1.inform_list.length == 1 &&
        this.index_v1.inform_list[0].content.length > 10
      ) {
        var target = this.$refs.contlist;
        this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
        var notic = this.$refs.notic;
        this.scroll();
      }
    },

    //循环滚动
    scroll() {
      var that = this; //因为定时器里要使用vue对象时不能用this, 在定时器中的 this 代表
      var target = this.$refs.contlist;
      var notic = this.$refs.notic;
      var initLeft = 0;
      if (this.scrollW < 0) {
        initLeft = this.scrollW * -1;
      }
      //定时器
      this.scrollTime = setInterval(function () {
        // console.log(that.$refs);
        initLeft++;
        if (notic && initLeft >= notic.offsetWidth) {
          initLeft = 0;
        }
        if (target) {
          target.style.left = "-" + initLeft + "px"; //获取不到translateX的值改用 left
        }

        // target.style = 'transform: translateX(-'+ initLeft +'px)';
      }, 20);
    },
    hiddenClock() {
      var clockContainerNode = document.querySelector(".experience");
      // var cut = document.querySelector(".cut_bj_you");
      var showBtn = document.querySelector(".show_btn");
      // cut.style.display = "none";
      clockContainerNode.classList.add("clock-container-hidden");
      setTimeout(() => {
        showBtn.style.display = "block";
        // showBtn.style.classList.add("show_free_btn");
      }, 1000);
    },
    showClock() {
      var clockContainerNode = document.querySelector(".experience");
      var showBtn = document.querySelector(".show_btn");
      // var cut = document.querySelector(".cut_bj");
      // cut.style.display = "block";
      showBtn.style.display = "none";
      clockContainerNode.classList.remove("clock-container-hidden");
      clockContainerNode.classList.add("clock-container-show");
    },

    // 0元体验提交按钮
    onSubmit() {
      let mobile = this.formInline.mobile; //用户手机号
      let ts = Date.parse(new Date()); //当前时间戳
      let cid = 2; //渠道号
      let subject_id = this.formInline.subject_id; //科目id
      let seed = cid + "-" + mobile + "-" + subject_id + "-" + ts;
      let sign = this.$md5(
        this.$md5(seed).toString().toLowerCase() + "landing_page"
      )
        .toString()
        .toLowerCase(); // 签名计算结果
      const reg = /^1[3-9][0-9]{9}$/; // 正则校验手机号
      if (!this.formInline.subject_id) {
        this.$message.error({
          duration: 1500,
          message: "请选择专业!",
        });
        return false;
      } else if (
        this.formInline.mobile == "" ||
        this.formInline.mobile <= 10 ||
        !reg.test(this.formInline.mobile)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确手机号!",
        });
        return false;
      } else if (this.$cookies.get("mobile") == mobile) {
        this.$message.error({
          duration: 2500,
          message: "一个手机号只允许提交一次,您已提交过,请勿重复提交!",
        });
        return false;
      } else {
        var data = {
          mobile: mobile,
          ts: ts,
          cid: cid,
          subject_id: subject_id,
          sign: sign,
        };
        api.landing_site(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            this.$message.success({
              duration: 1500,
              // message: "提交成功,一个手机号只允许提交一次,请勿重复提交!",
              message: "提交成功!",
            });
            this.formInline = {};
            this.$cookies.set("mobile", mobile, 30);
          } else {
            this.$message.error({
              duration: 1500,
              message: "提交失败,请重新提交!",
            });
          }
        });
      }
    },
    bmsubmit() {
      let mobile = this.bmform.mobile; //用户手机号
      let ts = Date.parse(new Date()); //当前时间戳
      let cid = 2; //渠道号
      let subject_id = this.bmform.region; //科目id
      let seed = cid + "-" + mobile + "-" + subject_id + "-" + ts;
      let sign = this.$md5(
        this.$md5(seed).toString().toLowerCase() + "landing_page"
      )
        .toString()
        .toLowerCase(); // 签名计算结果
      const reg = /^1[3-9][0-9]{9}$/; // 正则校验手机号
      if (!this.bmform.region) {
        this.$message.error({
          duration: 1500,
          message: "请选择专业!",
        });
        return false;
      } else if (
        this.bmform.mobile == "" ||
        this.bmform.mobile <= 10 ||
        !reg.test(this.bmform.mobile)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确手机号!",
        });
        return false;
      } else if (this.$cookies.get("mobile") == mobile) {
        this.$message.error({
          duration: 2500,
          message: "一个手机号只允许提交一次,您已提交过,请勿重复提交!",
        });
        return false;
      } else {
        var data = {
          mobile: mobile,
          ts: ts,
          cid: cid,
          subject_id: subject_id,
          sign: sign,
        };
        api.landing_site(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            this.$message.success({
              duration: 1500,
              // message: "提交成功,一个手机号只允许提交一次,请勿重复提交!",
              message: "提交成功!",
            });
            this.bmform = {};
            this.$cookies.set("mobile", mobile, 30);
            this.dialogVisible = false;
          } else {
            this.$message.error({
              duration: 1500,
              message: "提交失败,请重新提交!",
            });
            this.dialogVisible = false;
          }
        });
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    intervalChange(e) {
      this.interval = this.index_v1.pic_list[e].stand_second
        ? Number(this.index_v1.pic_list[e].stand_second) * 1000
        : 5000;
      // console.log(this.interval)
    },
    hoturl(url) {
      window.location.href = url;
    },
    // mouseOver() {
    //   // document.getElementById("hover").classList.add("deviation");
    // },
    // mouseleave() {
    //   // document.getElementById("hover").classList.remove("deviation");
    // },
  },
  created() {
    this.getSubject();
  },
  destroyed() {
    window.onscroll = function () { };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  background-color: rgba(0, 0, 0, 0.7);
}

::v-deep .el-loading-spinner .path {
  stroke: #ffffff;
}

.morepos {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.imgmask {
  position: absolute;
  width: 674px;
  height: 380px;
  top: 0;
  left: 0;
  transition: all 0.5s;

  img {
    width: 100%;
    height: 100%;
  }
}

.imgmask .mask {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 100%;
  background: rgba(0, 0, 0, 0.6);

  p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 15px;
    background: rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;

    span {
      width: 100%;
      line-height: 1.3;
    }
  }
}

.imgmask .mask .loadingimg {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.isred ul li:first-child {
  color: #fc3019 !important;
}

.isred ul li:first-child::after {
  background: url("../../assets/img/b8190cf6c72c58786f9be76f10ae139.png") !important;
}

.isred ul li:nth-child(5) {
  color: #fc3019 !important;
}

.isred ul li:nth-child(5)::after {
  background: url("../../assets/img/b8190cf6c72c58786f9be76f10ae139.png") !important;
}

.isred ul li img {
  position: relative;
  top: -15px;
}

.isred ul li span {
  // width: 386px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

/* 版心 */
nav {
  box-shadow: 0;
}

#app {
  background: #f6f9fc;
}

#content {
  position: relative;
  width: 1400px;
  /* height: 6000px; */
  margin: 20px auto;
}

::v-deep .el-button.is-round {
  padding: 15px 47px;
  font-size: 18px;
  border-radius: 24px;
}

::v-deep .nobk {
  background: none;
  color: #409eff;
}

.box1 {
  display: flex;
  justify-content: space-between;

  .l {
    padding: 20px 30px;
    background: #fff;
    border-radius: 12px;

    h2 {
      font-size: 24px;
      font-family: Microsoft YaqiHei;
      font-weight: bold;
      color: #30313f;
      margin-bottom: 23px;
    }

    .b {
      display: flex;

      .bl {
        margin-right: 30px;
        display: flex;
        flex-direction: column;

        img {
          width: 280px;
          height: 174px;
          border-radius: 10px;
          // cursor: pointer;
          overflow: hidden;
        }
      }

      .bl img:first-child {
        margin-bottom: 10px;
      }

      .br {
        display: flex;
        flex-direction: column;
        align-items: center;

        ul li {
          transition: all 0.3s;
          cursor: pointer;
          width: 416px;
          display: flex;
          padding-left: 11px;
          // font-weight: 600;
          position: relative;
          color: #4d5063;
          margin-top: 20px;
        }

        ul li:hover::after {
          background: url("../../assets/img/zuxun_lan.png") no-repeat center;
        }

        ul li:nth-child(4) {
          margin-bottom: 28px;
        }

        ul li:hover {
          color: #3e7eff;
        }

        ul li::after {
          transition: all 0.3s;
          content: "";
          width: 5px;
          height: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          display: block;
          background: url("../../assets/img/zixun (2).png") no-repeat center;
          background-size: 100%;
        }
      }
    }
  }
}

//考试速递完

.box2 {
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;

  .t {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    border-bottom: 2px solid #f7f9fc;

    .active {
      color: #3e7eff;
      position: relative;
    }

    .active::after {
      content: "";
      display: block;
      width: 26px;
      height: 3px;
      background: #3e7eff;
      position: absolute;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
    }

    span {
      margin: 0 70px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4d5063;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .b {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;

    .bl {
      .blt {
        width: 674px;
        height: 380px;
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
      }

      .blb {
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-left: -3px;
        justify-content: space-between;
        position: relative;

        .active {
          border: 2px solid #3e7eff;
          position: relative;

          .mask {
            display: none;
          }
        }

        .activee {
          border: 2px solid #3e7eff;
          position: relative;

          .mask {
            display: none;
          }
        }

        .activee::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #3e7eff;
        }

        .active::after {
          content: "";
          display: block;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #3e7eff;
        }

        .img:nth-child(2) {
          margin: 0 6px;
        }

        .img {
          width: 206px;
          height: 122px;
          cursor: pointer;
          padding: 3px;
          position: relative;
          box-sizing: border-box;
          border-radius: 8px;

          .mask {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            position: absolute;
            border-radius: 8px;
            top: 0;
            left: 0;
            text-align: center;
            line-height: 100%;
            background: rgba(0, 0, 0, 0.6);

            p {
              font-size: 10px;
              color: rgba(255, 255, 255, 0.8);
              width: 174px;
              position: absolute;
              bottom: 8px;
            }
          }

          .mask::after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url("../../assets/img/bofanghome.png") no-repeat center;
            background-size: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
          }
        }
      }
    }

    .brb {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .brbl {
        width: 530px;
        display: flex;
        flex-wrap: wrap;

        span {
          cursor: pointer;
          margin: 0 16px 0 0;
          position: relative;
          padding: 20px 0 20px 12px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3a404d;
          width: 160px;
          box-sizing: border-box;
          box-shadow: 0px 4px 9px 1px rgba(0, 21, 93, 0.06);
          border-radius: 6px;
          background: #f5f9ff;

          img {
            width: 32px;
            height: 30px;
            position: absolute;
            right: 10px;
          }
        }
      }

      .brbr {
        cursor: pointer;
        width: 140px;
        height: 142px;
        border-radius: 12px;
        overflow: hidden;
      }
    }

    .br {
      width: 420px;

      .brt {
        ul {
          margin-top: 20px;
          // border-bottom: 1px dashed rgba(62, 126, 255, 0.1);
        }

        ul li:hover {
          color: #3e7eff;
          background: url("../../assets/img/zuxun_lan.png") no-repeat left;
        }

        ul li span:first-child {
          width: 480px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        ul li {
          line-height: 1.15;
          padding-left: 13px;
          margin-bottom: 23px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #4d5063;
          background: url("../../assets/img/zixun (2).png") no-repeat left;
          background-size: 5px 10px;
        }
      }
    }
  }

  .yb .br {
    width: 690px;
    display: flex;

    .brt ul {
      border: none;
    }

    .brt ul li {
      width: 440px;
      box-sizing: border-box;
    }

    .brt ul li span:first-child {
      width: 431px;
    }

    .brb {
      margin-top: 0;
      margin-right: 17px;
      flex-direction: column;

      .brbl {
        width: auto;
        flex-direction: column;

        span {
          padding: 20px 0px 20px 16px;
        }
      }
    }
  }
}

.ysspan a {
  margin-bottom: 28px;
}

.ysspan a:last-child {
  margin-bottom: 0;
}

//医药类结束

.live {
  padding: 40px 30px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .l {
    background: #c7d2ff;
    border-radius: 10px;
    width: 820px;
    height: 542px;

    .lt {
      margin: 17px 0 10px;
      position: relative;
    }

    .lb {
      border-radius: 40px 10px 10px 10px;
      background: #5983fc;
      width: 820px;
      height: 370px;

      .lbtit {
        width: 762px;
        padding: 15px 0 0 66px;
        margin: 0 auto;
        font-size: 48px;
        line-height: 54px;
        color: #ffffff;
        font-weight: 600;
      }

      .lbbot {
        margin: 15px auto 0;
        width: 762px;
        height: 206px;
        line-height: 1;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 26px 36px;
        background: url("../../assets/img/baidi.png") no-repeat center;
        background-size: 100% 100%;

        .botl {
          width: 220px;
          height: 154px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .botr {
          margin-left: 33px;

          .botrt {
            padding: 9px 30px;
            box-sizing: border-box;
            width: 227px;
            height: 50px;
            background: linear-gradient(90deg,
                #5883ff 0%,
                #5983fc 0%,
                rgba(89, 131, 252, 0.27) 73%,
                rgba(255, 255, 255, 0) 100%);
            border-radius: 6px 0px 0px 6px;
            font-size: 32px;
            color: #ffffff;
            font-weight: 600;
          }

          .botrb {
            margin-top: 12px;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
          }
        }
      }

      .lbbuton {
        margin: 0 auto;
        line-height: 1;
        width: 760px;
        cursor: pointer;
        height: 70px;
        background: #464646;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .butl {
          padding-left: 20px;
          font-size: 28px;
          font-weight: 600;
          color: #ffffff;

          span {
            font-weight: 400;
          }
        }
      }
    }
  }

  .r {
    width: 505px;

    ul li:last-child {
      margin-bottom: 0;
    }

    ul::after {
      content: "";
      display: block;
      width: 4px;
      height: 500px;
      background: #f6f6f6;
      position: absolute;
      top: 46px;
      left: 22px;
    }

    ul {
      position: relative;
      margin-left: 20px;

      li {
        margin-bottom: 30px;

        .t {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #1d1d1d;

          span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            margin: 8px 0 0 9px;
          }

          .img {
            z-index: 1;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            line-height: 46px;
            text-align: center;
            background: #dce7ff;
            margin-right: 16px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .b:hover {
          background: #3e7eff;
          color: #fff;
        }

        .b:hover .but {
          background: #3e7eff;
          color: #fff;
          border: 1px solid #ffffff;
        }

        .jsj:hover {
          background: #f5f5f5 !important;
          color: #000b2f !important;
        }

        .jsj {
          background: #f5f5f5 !important;
        }

        .b {
          box-sizing: border-box;
          cursor: pointer;
          margin-left: 62px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 14px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000b2f;
          background: #f5f9ff;
          border-radius: 0px 10px 10px 10px;

          .js {
            background: #f5f5f5 !important;
            color: #aaa !important;
            border: 1px solid #aaaaaa !important;
          }

          .zbz {
            background: #ff6500;
          }

          .jjks {
            background: #3e7eff;
          }

          .but {
            box-sizing: border-box;
            transition: all 0.3s;
            width: 120px;
            height: 36px;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 18px;
            line-height: 36px;

            img {
              width: 16px;
              height: 16px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

//直播结束

::v-deep .el-image {
  width: 100%;
  min-width: 1400px;
}

::v-deep .demo-form-inline {
  display: flex;
  align-items: center;

  .el-form-item {
    margin-bottom: 0;
  }
}

//课程推荐结束

/* 轮播 */

/deep/.el-carousel__container {
  height: auto;
}

// /deep/.el-carousel__item:nth-child(2n) {
//   // background-color: #99a9bf;
//   border-radius: 12px;
// }

// /deep/.el-carousel__item:nth-child(2n + 1) {
//   // background-color: #d3dce6;
//   border-radius: 12px;
// }

#content .banner {
  width: 100%;
  max-height: 360px;
  margin: auto;
  cursor: pointer;
  border-radius: 12px;
}

/deep/.el-carousel__arrow--left {
  // left: 0;
  width: 50px;
  height: 50px;
  background: #000d27;
  opacity: 0.2;
  // border-radius: 0px 8px 8px 0px;
  font-size: 22px;
}

/deep/.el-carousel__arrow--left:hover {
  background: #000d27;
  opacity: 0.4;
}

/deep/.el-carousel__arrow--right {
  // right: 0;
  width: 50px;
  height: 50px;
  background: #000d27;
  opacity: 0.2;
  // border-radius: 8px 0px 0px 8px;
  font-size: 22px;
}

/deep/.el-carousel__arrow--right:hover {
  background: #000d27;
  opacity: 0.4;
}

/* 指示器小点 */

/deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 8px;
  height: 8px;
  background: #ffffff;
  // opacity: 0.3;
  border-radius: 4px;
}

/deep/.is-active .el-carousel__button {
  width: 20px;
  height: 8px;
  background: #ffffff;
  opacity: 0.8;
  border-radius: 4px;
}

/* 通知 */

.notice_box {
  width: 100%;
  background: #fff;
  margin-top: -4px;
  height: 50px;
  justify-content: center;
  // background-image: url("../../assets/img/beijingtu.png");
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  // margin-top: 12px;
}

.notice_box .one {
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  background: #fff;
  z-index: 1;
}

.notice_box div:nth-child(1) img {
  width: 22px;
  height: 20px;
  margin: 0 20px 0 0px;
}

.notice_box div:nth-child(1) p {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #3e7eff;
  margin-right: 10px;
  z-index: 2;
}

.notice {
  width: 100%;
  position: fixed;
  height: 50px;
  overflow: hidden;
}

.notice_box .notice {
  display: flex;
  align-items: center;
  position: relative;
}

.contlist {
  position: absolute;
  /* top: 1px; */
  width: 1000%;
  /* height: 22px; */
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  display: flex;
}

.contlist div {
  width: 814px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

/* 公司简介 */

.company_box {
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.company_left {
  width: 900px;
  height: 100%;
  background-image: url("../../assets/img/jianjie.png");
  display: flex;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
}

.txt_content {
  margin-left: 394px;
}

.txt_content div:nth-child(1) {
  width: 120px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #222222;
  margin: 10px 0 7px;
}

.txt_content div:nth-child(2) {
  width: 328px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #222222;
}

.txt_content div:nth-child(3) {
  width: 20px;
  height: 3px;
  background: #3e7eff;
  border-radius: 2px;
  margin: 14px 0 25px;
}

.txt_content div:nth-child(4) {
  width: 476px;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  // margin: 25px 0 20px;
}

.txt_content div:nth-child(5) {
  width: 476px;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  // margin-bottom: 21px;
  margin: 25px 0;
}

.txt_content div:nth-child(6) {
  width: 476px;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-bottom: 34px;
}

/* 咨询电话 */

.tel_num {
  width: 280px;
  height: 340px;
  // background-image: url("../../assets//img/zixun.png");
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

  div:nth-child(1) {
    height: 28px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #222222;
    text-align: center;
    margin: 25px 0 20px;
  }

  div:nth-child(2) {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3e7eff;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
      margin: 0 11px 0 55px;
    }
  }

  div:nth-child(3) {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3e7eff;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
      margin: 0 11px 0 55px;
    }
  }

  img {
    width: 280px;
    // height: 172px;
    display: block;
    margin: 0px auto 0;
  }
}

/* 底部体验课 */

.experience {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  background: #2ea7fa;
  // background: url('../../assets/img/home_bg.png')no-repeat;
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 99;
  overflow: hidden;
}

.experience_center {
  width: 1200px;
  height: 90px;
  margin: auto;
}

// .experience_btn .cut_bj_zuo {
//   height: 90px;
//   // position: absolute;
//   // left: 0;
//   // margin-left: -200px;
// }
.experience_btn {
  position: relative;
  min-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  // background-image: url("../../assets/img/center_beijing.png");
  // background: pink;
}

/* 免费资料领取按钮 */

.show_btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 35px;
  height: 90px;
  background: #599bf9;
  border-radius: 0px 8px 8px 0px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  /* writing-mode: vertical-rl; */
  writing-mode: vertical-lr;
  text-align: center;
  line-height: 30px;
  display: none;
}

.show_free_btn {
  transform: translateX(0);
  animation: btn 0.5s linear;
  -moz-animation: btn 0.5s linear;
  -webkit-animation: btn 0.5s linear;
  -o-animation: btn 0.5s linear;
}

.experience_btn .btn_left {
  // width: 600px;
  display: flex;
  justify-content: space-between;
  // margin-top: 96px;
}

/* 标题 */

.title_box {
  .more {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: #909499;

    img {
      width: 8px;
      margin-left: 12px;
    }
  }

  position: relative;
  width: 100%;
  text-align: center;
  margin: 80px 0 40px;
}

.title_box div:nth-child(1) {
  font-size: 40px;
  font-family: Microsoft YaqiHei;
  font-weight: bold;
  color: #ebedf3;
  z-index: -1;
}

.title_box div:nth-child(2) {
  font-size: 34px;
  font-family: Microsoft YaqiHei;
  font-weight: bold;
  color: #1d1d1d;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
}

.title_box div:nth-child(2)::after {
  content: "";
  display: block;
  width: 112px;
  height: 6px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #152ff5 0%, #f7f9fc 100%);
}

/deep/.btn_left .el-input__inner {
  width: 200px;
  height: 46px;
  border: 1px solid #ffffff;
  background: rgba(0, 0, 0, 0);
  color: #ffffff;
}

/deep/.btn_left .el-input__inner::placeholder {
  color: #ffffff;
}

/deep/.btn_left .el-select .el-input .el-select__caret {
  color: #fff;
}

/deep/.btn_left .el-select .el-input.is-focus .el-input__inner {
  border-color: #fff;
}

.btn_left .at_once {
  width: 160px;
  height: 46px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #599af8;
  text-align: center;
  line-height: 46px;
  // z-index: 9999;
}

.experience_btn .btn_right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  right: 0;
  width: 32px;
  height: 32px;
  // margin-top: 16px;
  /* margin: 16px 0 0 268px; */
}

.clock-container-show {
  transform: translateX(0px);
  animation: show 1s;
  -moz-animation: show 1s;
  -webkit-animation: show 1s;
  -o-animation: show 1s;
}

.clock-container-hidden {
  transform: translateX(-100%);
  animation: hidden 0.5s linear;
  -moz-animation: hidden 0.5s linear;
  -webkit-animation: hidden 0.5s linear;
  -o-animation: hidden 0.5s linear;
}

@keyframes show {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes hidden {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    display: none;
  }
}

@-webkit-keyframes hidden

/* Safari 和 Chrome */
  {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    display: none;
  }
}

::v-deep .bmdialog .el-dialog__title {
  font-weight: 600;
  font-family: PingFang SC;
  font-size: 22px;
  color: #3e7eff;
  display: block;
  text-align: center;
}

::v-deep .bmdialog .el-dialog__header {
  padding: 30px 0 15px;
}

::v-deep .bmdialog {
  .el-dialog {
    border-radius: 8px;
  }

  .el-dialog__body {
    padding: 0 20px 8px;
  }

  .el-dialog__body .detail {
    padding: 0 20px 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }

  .el-form-item__content {
    width: 290px;
  }

  .el-select {
    width: 100%;
  }

  .el-dialog__footer {
    padding: 0 50px 30px;
  }

  .el-button {
    width: 100%;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #3e7eff;
    box-shadow: 0px 8px 18px 0px rgba(37, 97, 218, 0.3);
    border-radius: 26px;
  }
}

::v-deep .el-form-item__content::before,
::v-deep .el-form-item__content::after,
::v-deep .el-form-item::before,
::v-deep .el-form-item::after {
  display: none;
}

.hotmask {
  background: #000;
  opacity: 0.7;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.hotimg {
  z-index: 13;
  width: 760px;
  height: 688px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hotimg img {
  cursor: pointer;
  width: 100%;
}

.hotclose {
  width: 56px !important;
  height: 56px;
  position: absolute;
  top: 145px;
  right: 95px;
}

// .hotbanner {
// height: 142px;
// }
</style>
